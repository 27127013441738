import {
    DriveFolderUpload as DriveFolderUploadIcon,
    Home as HomeIcon,
    NotificationsNone as NotificationsNoneIcon,
} from '@mui/icons-material'
import { Sidebar as Menu } from 'primereact/sidebar'
import { useState } from 'react'
import { MdMenu, MdPowerSettingsNew } from 'react-icons/md'
import styled from 'styled-components'

import packageJson from '../../../package.json'
import FactoryIcon from '../../assets/icons/SVG/FactoryIcon'
import VersionIcon from '../../assets/icons/SVG/VersionIcon'
import { useUserContext } from '../../context/UserContext'
import { StorageKeys } from '../../models/ILocalStorageService'
import { COLORS } from '../Colors'
import { ROUTES } from '../utils/AppRoutes'
import { makeLocalStorageService } from '../utils/LocalStorageService'
import ItemMenu from './ItemMenu'
import UserProfileComponent from './UserProfileComponent'

export const SideMenu = () => {
    const { user } = useUserContext()
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false)
    const { removeItem } = makeLocalStorageService()

    const handleMenuVisibility = () => {
        setIsSideMenuVisible((isSideMenuVisible) => !isSideMenuVisible)
    }

    const onLogout = () => {
        removeItem(StorageKeys.USERTOKEN)
    }

    const menuOptions: { icon: JSX.Element; label: string; path: string }[] = [
        {
            icon: <NotificationsNoneIcon />,
            label: 'Lançamento',
            path: ROUTES.NEWS,
        },
        {
            icon: <FactoryIcon />,
            label: 'Fábricas',
            path: ROUTES.MILLSLIST,
        },
        {
            icon: <DriveFolderUploadIcon />,
            label: 'Importação',
            path: ROUTES.IMPORT,
        },
    ]

    return (
        <>
            <MenuButtonContainer>
                <MenuButton
                    onClick={handleMenuVisibility}
                    isVisible={isSideMenuVisible}
                >
                    <MdMenu />
                </MenuButton>
            </MenuButtonContainer>
            <SideBar
                dismissable={false}
                showCloseIcon={false}
                modal={false}
                closeOnEscape={false}
                position="right"
                visible={isSideMenuVisible}
                onHide={handleMenuVisibility}
            >
                <SideBarContent>
                    <TopContainer>
                        <UserProfileComponent />
                        <ItemsContainer>
                            <ItemMenu
                                label="Início"
                                icon={<HomeIcon />}
                                linkTo={ROUTES.HOME}
                            />

                            {user &&
                                menuOptions.map((item) => (
                                    <ItemMenu
                                        key={item.label}
                                        label={item.label}
                                        icon={item.icon}
                                        linkTo={item.path}
                                    />
                                ))}
                        </ItemsContainer>
                    </TopContainer>

                    <BottomContainer>
                        <ItemMenu
                            label={`Versão ${packageJson.version}`}
                            icon={<VersionIcon />}
                            isClickable={false}
                        />
                        <ItemMenu
                            label="Sair"
                            icon={<MdPowerSettingsNew size={25} />}
                            linkTo={ROUTES.LOGIN}
                            onClick={() => onLogout()}
                        />
                    </BottomContainer>
                </SideBarContent>
            </SideBar>
        </>
    )
}

const MenuButtonContainer = styled.div`
    &:hover {
        background-color: ${COLORS.veryLightPink};
    }
    border-radius: 50%;
    height: 40px;
`

const MenuButton = styled.button<{ isVisible: boolean }>`
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    color: ${(props) =>
        props.isVisible ? `${COLORS.primaryOrange}` : `${COLORS.black}`};
    border: none;
    display: flex;
    align-content: center;
    font-size: 30px;
    margin-top: 4px;
`

const SideBar = styled(Menu)`
    background-color: ${COLORS.primaryWhite};
    margin-top: 65px;
    height: calc(100vh - 60px);
    padding: 0 !important;
`

const SideBarContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-left: 2px solid ${COLORS.borderGray};
`
const TopContainer = styled.div``

const ItemsContainer = styled.div``

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${COLORS.lightBlueGreyTwo};
`

export default SideMenu
