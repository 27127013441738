import React from 'react'
import { Routes as AppRoutes, Route } from 'react-router-dom'

import PrivateRoute from './commons/components/PrivateRouteComponent'
import { ROUTES } from './commons/utils/AppRoutes'
import { UserContextProvider } from './context/UserContext'
import HomeScreen from './home/HomeScreen'
import ImportScreen from './import/ImportScreen'
import Login from './login/Login'
import MillManagementFormScreen from './millManegement/MillManagementFormScreen'
import MillManagementListScreen from './millManegement/MillManagementListScreen'
import { Auth } from './models/Auth'
import FeatureAndVersionScreen from './news/FeatureAndVersionScreen'

const Routes: React.FC = () => {
    const authentitcation = new Auth()
    return (
        <UserContextProvider>
            <AppRoutes>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route
                    path={ROUTES.HOME}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<HomeScreen />}
                        />
                    }
                />
                <Route
                    path={ROUTES.NEWS}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<FeatureAndVersionScreen />}
                        />
                    }
                />
                <Route
                    path={ROUTES.IMPORT}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<ImportScreen />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MILLSLIST}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<MillManagementListScreen />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MILLSNEW}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<MillManagementFormScreen />}
                        />
                    }
                />
                <Route
                    path={ROUTES.MILLEDIT}
                    element={
                        <PrivateRoute
                            auth={authentitcation}
                            component={<MillManagementFormScreen />}
                        />
                    }
                />
            </AppRoutes>
        </UserContextProvider>
    )
}

export default Routes
