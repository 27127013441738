import { useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import MainHeaderComponent from '../../commons/components/MainHeaderComponent'
import TabSelectorComponent from '../../commons/components/TabSelectorComponent'
import FeatureTabContentScreen from '../FeatureTabContentScreen'
import VersionTabContentScreen from '../VersionTabContentScreen'

function FeatureAndVersionScreen(): JSX.Element {
    const featuresTab = {
        label: 'Features',
        renderTabComponent: () => <FeatureTabContentScreen />,
    }

    const versionsTab = {
        label: 'Versões',
        renderTabComponent: () => <VersionTabContentScreen />,
    }

    const pageTabs = [featuresTab, versionsTab]

    const [selectedTab, setSelectedTab] = useState(featuresTab)

    return (
        <PageContainer>
            <MainHeaderComponent />
            <PageTitle>Lançamento de Novidades</PageTitle>
            <TabSelectorComponent
                tabNames={pageTabs}
                getSelectedTab={setSelectedTab}
            />
            {selectedTab.renderTabComponent()}
        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    margin: auto;
    font-weight: normal;
    margin-top: 25px;
`

export default FeatureAndVersionScreen
