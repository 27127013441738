import styled from 'styled-components'

import { COLORS } from '../Colors'
import { SizeTypes } from '../utils/Types'

type LoadingComponentProps = {
    size?: SizeTypes
}

function LoadingComponent({
    size = 'large',
}: LoadingComponentProps): JSX.Element {
    if (size === 'small')
        return <SmallLoading data-testid="loading-component" />

    if (size === 'medium')
        return <MediumLoading data-testid="loading-component" />

    return <LoadingContainer data-testid="loading-component" />
}

const LoadingContainer = styled.div`
    border: 5px solid ${COLORS.borderGray};
    border-top: 5px solid ${COLORS.primaryOrange};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const SmallLoading = styled(LoadingContainer)`
    border: 2px solid ${COLORS.borderGray};
    border-top: 2px solid ${COLORS.primaryOrange};
    width: 13px;
    height: 13px;
`

const MediumLoading = styled(LoadingContainer)`
    border: 4px solid ${COLORS.borderGray};
    border-top: 4px solid ${COLORS.primaryOrange};
    width: 25px;
    height: 25px;
`

export default LoadingComponent
