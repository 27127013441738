import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { makeLocalStorageService } from '../commons/utils/LocalStorageService'
import { MillType, UserTokenType, UserType } from '../commons/utils/Types'
import { StorageKeys } from '../models/ILocalStorageService'

export type UserContextType = {
    user: UserType | any
    mill: MillType | any
    loadUser: () => void | any
}

export const UserContext = React.createContext<UserContextType>({
    user: null,
    mill: null,
    loadUser: () => null,
})

export const useUserContext = () => React.useContext(UserContext)

type UserContextProviderProps = {
    children: React.ReactNode
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
    children,
}) => {
    const [user, setUser] = useState<UserType | null>(null)
    const [mill, setMill] = useState<MillType | null>(null)

    const { getItem } = makeLocalStorageService()

    const loadUser = useCallback(() => {
        const userToken = getItem<UserTokenType>(StorageKeys.USERTOKEN)

        if (userToken) {
            setUser(userToken.accessToken.payload)
            setMill(userToken.mill)
        } else {
            setUser(null)
            setMill(null)
        }
    }, [])

    useEffect(() => {
        if (!user) loadUser()
    }, [user, loadUser])

    const contextValue = useMemo(
        () => ({
            user,
            mill,
            loadUser,
        }),
        [user, mill]
    )

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}
