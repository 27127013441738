import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../Colors'

export type ItemMenuProps = {
    label: string
    icon: JSX.Element
    linkTo?: string
    onClick?: () => void | null
    isClickable?: boolean
}

function ItemMenu({
    label,
    icon,
    linkTo,
    onClick,
    isClickable = true,
}: ItemMenuProps): JSX.Element {
    if (!linkTo) {
        return (
            <Item key={label} onClick={onClick} isClickable={isClickable}>
                {icon}
                <ItemLabel>{label}</ItemLabel>
            </Item>
        )
    }
    return (
        <Link to={linkTo}>
            <Item key={label} onClick={onClick} isClickable={isClickable}>
                {icon}
                <ItemLabel>{label}</ItemLabel>
            </Item>
        </Link>
    )
}

const Item = styled.div<{ isClickable: boolean }>`
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    padding: 11px 15px;
    color: ${COLORS.brownishGrey};
    background-color: ${COLORS.primaryWhite};
    &:hover {
        background-color: ${(props) =>
            props.isClickable ? COLORS.veryLightPink : COLORS.primaryWhite};
    }
    &:active {
        color: ${(props) =>
            props.isClickable ? COLORS.primaryOrange : COLORS.brownishGrey};
    }
`

const ItemLabel = styled.span`
    margin-left: 17px;
`
export default ItemMenu
