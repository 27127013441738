import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { COLORS } from '../Colors'

export type UnmountedTabTypes = {
    label: string
    renderTabComponent: () => JSX.Element
}

type MountedTabTypes = {
    label: string
    value: number
    selected: boolean
    renderTabComponent: () => JSX.Element
}

export type TabSelectorComponentProps = {
    tabNames: UnmountedTabTypes[]
    getSelectedTab: (selectedTab: UnmountedTabTypes) => void
}

function TabSelectorComponent({
    tabNames,
    getSelectedTab,
}: TabSelectorComponentProps): JSX.Element {
    const [pageTabs, setpageTabs] = useState<MountedTabTypes[] | []>([])

    useEffect(() => {
        const mountedPageTabs = mountPageTabs(tabNames)
        setpageTabs(mountedPageTabs)
    }, [])

    const mountPageTabs = (
        tabNames: UnmountedTabTypes[]
    ): MountedTabTypes[] => {
        const mountedTabs = tabNames.map((tab, index) =>
            index === 0
                ? { ...tab, value: index, selected: true }
                : { ...tab, value: index, selected: false }
        )

        return mountedTabs
    }
    const handleSelectedTab = (tabValue: number): void => {
        const selectedTab = pageTabs.find((tab) => tab.value === tabValue)
        if (!selectedTab) return

        const updatedPageTabs = pageTabs
            .map((tab) => {
                if (tab.value === selectedTab.value)
                    return { ...selectedTab, selected: true }
                return { ...tab, selected: false }
            })
            .sort((firstValue, secondValue) => {
                if (firstValue.value > secondValue.value) return 1
                if (secondValue.value > firstValue.value) return -1
                return 0
            })

        getSelectedTab(selectedTab)
        setpageTabs(updatedPageTabs)
    }

    const renderTabs = (): JSX.Element[] =>
        pageTabs.map((tab) => (
            <Tab
                selected={tab.selected}
                onClick={() => handleSelectedTab(tab.value)}
                key={`${tab.label}-${tab.value}`}
            >
                {tab.label}
            </Tab>
        ))

    return <TabSelectorContainer>{renderTabs()}</TabSelectorContainer>
}

const TabSelectorContainer = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${COLORS.borderGray};
    align-items: flex-end;
    justify-content: center;
    height: 95px;
`

interface ITabProps {
    selected?: boolean
}

const Tab = styled.button<ITabProps>`
    border: none;
    background-color: ${COLORS.secondaryWhite};
    font-family: Roboto;
    font-size: 18px;
    line-height: 2;
    width: 230px;
    margin-bottom: ${({ selected }) => (selected ? 'none' : '4px')};
    color: ${({ selected }) =>
        selected ? COLORS.primaryOrange : COLORS.primaryGray};
    border-bottom: ${({ selected }) => (selected ? 'solid' : 'none')};
    border-color: ${({ selected }) =>
        selected ? COLORS.primaryOrange : 'none'};
    border-bottom-width: ${({ selected }) => (selected ? '4px' : 'none')};
`

export default TabSelectorComponent
