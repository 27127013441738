import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import styled from 'styled-components'

import { COLORS } from '../Colors'

export type SwitchButtonComponentProps = {
    active: boolean
    onClick: (active: boolean) => void
    testid?: string
}

function SwitchButtonComponent({
    active,
    onClick,
    testid,
}: SwitchButtonComponentProps): JSX.Element {
    const iconStyle = { width: 18, height: 18 }
    return (
        <ComponentContainer>
            <SliderContainer
                onClick={() => onClick(!active)}
                data-testid={testid}
            >
                <Slider selected={active}>
                    <Oval selected={active}>
                        {active ? (
                            <CheckIcon
                                sx={{
                                    color: COLORS.success,
                                    ...iconStyle,
                                }}
                            />
                        ) : (
                            <CloseIcon
                                sx={{
                                    color: COLORS.inactive,
                                    ...iconStyle,
                                }}
                            />
                        )}
                    </Oval>
                </Slider>
            </SliderContainer>
            <Text>{active ? 'Ativo' : 'Inativo'}</Text>
        </ComponentContainer>
    )
}

interface IButtonProps {
    selected?: boolean
}

const ComponentContainer = styled.div`
    display: flex;
    align-items: center;
`

const SliderContainer = styled.label`
    display: inline-block;
    position: relative;
`

const Oval = styled.div<IButtonProps>`
    background-color: ${COLORS.primaryWhite};
    bottom: 4px;
    content: '';
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 18px;
    height: 18px;
    flex-grow: 0;
    padding: 1px;
    ${({ selected }) =>
        selected &&
        `
    transform: translateX(23px);
    `}
    border-radius: 50%;
    margin: -3px;
`

const Slider = styled.div<IButtonProps>`
    background-color: ${({ selected }) =>
        selected ? COLORS.success : COLORS.inactive};
    bottom: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.4s;
    width: 23px;
    height: 20px;
    flex-grow: 0;
    margin: 0 8px 0 0;
    padding: 1px 1px 1px 21px;
    border-radius: 11.5px;
`

const Text = styled.span`
    width: 42px;
    height: 16px;
    flex-grow: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.primaryGray};
    vertical-align: middle;
    display: inline-block;
`

export default SwitchButtonComponent
